import React from "react";
import Layout from "../components/layout/layout";
import SEOComponent from "../components/seo";
// import AmberScore from "../components/reusable/AmberScore";
// import MeetAmber from "../components/homepage/MeetAmber";
// import ClientsHome from '../components/homepage/ClientHome';
// import VideoCardHome from '../components/homepage/VideoCardHome';
// import ThreeSteps from '../components/homepage/ThreeSteps';
// import HomeStories from '../components/homepage/HomeStories';
// import ScaleSecurity from '../components/homepage/ScaleSecurity';
import LoadingChat from "../components/Loading";

const IndexPage = () => (
  // <Layout>
    <>
      <SEOComponent title="Amber by inFeedo&#8482; | Employee Experience Platform" description="Amber is an AI chatbot that talks to your employees to help you identify those who are unhappy, disengaged or about to leave. Join 100+ CHROs who have tossed their employee experience playbooks to take the first step to becoming an employee-first leader." />
      <LoadingChat/>
    </>
  // </Layout>
)

export default IndexPage