import React, { Component } from 'react';
import amber_image from '../../static/images/amber-blue.png';

export default class LoadingChat extends Component {
    render() {
        if(typeof window !== `undefined` && window && window.location && window.location.href && (window.location.href === 'https://infeedo.com/' || window.location.href === 'http://localhost:8000/')){
            window.location.href = 'https://www.infeedo.ai/';
        }
        return (
            <div className="amber-loading wave">
                {/* <img src={amber_image} alt="amber" width="200px" />
                <div className="progress-bar">
                    <br />
                    <progress className="progress is-link is-small" max="100"></progress>
                </div> */}
            </div>
        );
    }
}
